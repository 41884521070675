
/**
 * 
 * @param {string} name 
 * @param {string} [url]
 * @returns {string}
 */
function getUrlParameter(name, url = null) {
    if (!name) return null;
    return new URL(url || location.href).searchParams.get(name);
}

/**
 * 
 * @param {string} name 
 * @param {boolean} reload 
 */
function removeUrlParam(name, reload) {
    let url = new URL(location.href);
    url.searchParams.delete(name);

    if (reload) {
        location.href = url.toString();
    } else {
        history.pushState(null, null, url);
    }
}

/**
 * 
 * @param {string} name 
 * @param {string} value 
 * @param {boolean} reload 
 */
function addUrlParam(name, value, reload) {
    let url = new URL(location.href);
    url.searchParams.set(name, value);

    if (reload) {
        location.href = url.toString();
    } else {
        history.pushState(null, null, url);
    }
}

export { getUrlParameter, removeUrlParam, addUrlParam };