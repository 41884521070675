import { handleError } from "./debug";

/** @typedef {string | Element | NodeListOf<Element> | Element[] | (() => Element[])} ElementsOrSelector */

/**
 * аналог delegate, т.е. для случаев, когда event нужно вешать на контейнер или весь документ, а не на элемент
 * @param {ElementsOrSelector} parent 
 * @param {string} event 
 * @param {string | string[]} selector 
 * @param {(element: HTMLElement, event: MouseEvent) => void} handler 
 */
function addEventDelegate(parent, event, selector, handler) {
    let parents = getElements(parent);

    let events = event.split(' ').map(e => e.trim());
    if (typeof selector == 'string' && selector.includes(',')) selector = selector.split(',').map(e => e.trim());
    let matchSelector = Array.isArray(selector) ? selector.map(e => `${e}, ${e} *`).join(', ') : `${selector}, ${selector} *`;
    let closestSelector = Array.isArray(selector) ? selector.join(', ') : selector;

    for (let _event of events) {
        for (let _parent of parents) {
            _parent.addEventListener(_event, evt => {
                if (evt.target != null && evt.target instanceof Element) {
                    let element = evt.target;
                    if (element.matches(matchSelector)) {
                        handler.apply(null, [element.closest(closestSelector), evt]);
                    }
                } else {
                    handleError(new Error("event mismatch"), true, true, `${evt.type}`);
                }
            }, false);
        }
    }
}

/**
 * обертка над стандартным addEventListener с селектором и поддержкой нескольких типов событий
 * @param {ElementsOrSelector} element 
 * @param {string} event 
 * @param {(element: HTMLElement, event: MouseEvent) => void} handler 
 */
function addEvent(element, event, handler) {
    let elements = getElements(element);
    let events = event.split(' ').map(e => e.trim());

    for (let item of elements) {
        for (let _event of events) {
            item.addEventListener(_event, evt => {
                handler.apply(null, [item, evt]);
            });
        }
    }
}

/**
 * 
 * @param {ElementsOrSelector} element 
 * @returns 
 */
function getElements(element) {
    if (element == null) return [];
    let elements = null;

    if (typeof element == "string") elements = [...document.querySelectorAll(element)];
    else if (element instanceof Element) elements = [element];
    else if (element instanceof NodeList) elements = [...element];
    else if (Array.isArray(element)) elements = element
    else if (typeof element === 'function') elements = element();
    else elements = [element];

    return elements;
}

/**
 * 
 * @param {ElementsOrSelector} element 
 * @param {string} name 
 * @returns 
 */
function hasClass(element, name) {
    let elements = getElements(element);

    for (let item of elements) {
        if (!item.classList.contains(name)) return false;
    }

    return true;
}

/**
 * 
 * @param {ElementsOrSelector} element 
 * @param {string} name 
 * @param {boolean} [value] 
 */
function toggleClass(element, name, value = undefined) {
    if (!name) return;
    let classList = name.split(' ');
    let elements = getElements(element);

    for (let item of elements) {
        for (let _class of classList) {
            item.classList.toggle(_class, value);
        }
    }
}

/**
 * 
 * @param {ElementsOrSelector} element 
 */
function show(element) {
    toggleClass(element, 'd-none', false);
}

/**
 * 
 * @param {ElementsOrSelector} element 
 */
function hide(element) {
    toggleClass(element, 'd-none', true);
}

/**
 * 
 * @param {ElementsOrSelector} element 
 * @param {boolean} [value] 
 * @returns 
 */
function toggle(element, value) {
    if (value == undefined) {
        toggleClass(element, 'd-none');
        return;
    }

    toggleClass(element, 'd-none', !value);
}

/**
 * 
 * @param {ElementsOrSelector} elementsOrSelector 
 */
function empty(elementsOrSelector) {
    let elements = getElements(elementsOrSelector);
    for (let element of elements) {
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    }
}

/**
 * 
 * @param {ElementsOrSelector} element 
 * @param {string} property 
 * @param {string} value 
 */
function setProperty(element, property, value) {
    let elements = getElements(element);
    for (let element of elements) {
        element[property] = value;
    }
}

/**
 * 
 * @param {ElementsOrSelector} element 
 * @param {string} value,
 * @param {boolean} triggerChangeEvent
 */
function setValue(element, value, triggerChangeEvent) {
    let elements = getElements(element);
    for (let element of elements) {
        element.value = value;
        if (triggerChangeEvent) element.dispatchEvent(new Event("change", { bubbles: true }));
    }
}

/**
 * 
 * @param {string} html 
 * @returns 
 */
function htmlToDocumentFragment(html) {
    return document.createRange().createContextualFragment(html);
}

/**
 * используется, если htmlToDocumentFragment работает некорректно.
 * Например, в случае с тегами tr и td
 * @param {string} html
 */
function htmlToDocumentFragmentCompat(html) {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content;
}

/**
 * 
 * @param {string | Element} element 
 * @param {string} html 
 * @returns 
 */
function appendHtml(element, html) {
    if (!element || !html) return;
    let target = element instanceof Element ? element : document.querySelector(element);
    target.append(htmlToDocumentFragment(html));
}

/**
 * 
 * @param {string | Element} element 
 * @param {string} html 
 * @returns 
 */
function prependHtml(element, html) {
    if (!element || !html) return;
    let target = element instanceof Element ? element : document.querySelector(element);
    target.prepend(htmlToDocumentFragment(html));
}

/**
 * 
 * @param {string | Element} element 
 * @param {string} html 
 * @returns 
 */
function setHtml(element, html) {
    if (!element) return;
    let target = element instanceof Element ? element : document.querySelector(element);
    if (!target) return;

    empty(target);
    if (html) target.append(htmlToDocumentFragment(html));
}

/**
 * 
 * @param {string | Element} element 
 * @param {string} html 
 * @returns 
 */
function replaceWithHtml(element, html) {
    let target = element instanceof Element ? element : document.querySelector(element);

    if (!target) return;

    if (!element || !html) {
        target.innerHTML = '';
        return;
    }

    target.replaceWith(htmlToDocumentFragment(html));
}

/**
 * 
 * @param {string | Element} element 
 * @param {string} html 
 * @returns 
 */
function insertHtmlAfter(element, html) {
    if (!element || !html) return;
    let target = element instanceof Element ? element : document.querySelector(element);
    target.after(htmlToDocumentFragment(html));
}

/**
 * 
 * @param {string | Element} element 
 * @param {string} html 
 * @returns 
 */
function insertHtmlBefore(element, html) {
    if (!element || !html) return;
    let target = element instanceof Element ? element : document.querySelector(element);
    target.before(htmlToDocumentFragment(html));
}

function documentHeight() {
    let body = document.body,
        html = document.documentElement;

    let height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

    return height;
}

/**
 * 
 * @param {ElementsOrSelector} selector 
 * @param {(event: MouseEvent) => void} handler 
 */
function onClickOutside(selector, handler) {
    document.addEventListener('click', event => {
        let run = true;

        for (let _el of getElements(selector)) {
            if (_el == event.target || _el.contains(event.target)) {
                run = false;
                break;
            }
        }

        if (run) handler(event);
    });
}

/**
 * @typedef {object} DataStorage
 * @property {(element: HTMLElement, key: string, obj: any) => void} put
 * @property {(element: HTMLElement, key: string) => any} get
 * @property {(element: HTMLElement, key: string) => boolean} has
 * @property {(element: HTMLElement, key: string) => void} remove
 */

/**
 * замена для .data() в jquery. В большинстве случаев должно хватить стандартного HTMLElement.dataset,
 * но в него можно добавлять только строки, а сюда любые объекты, в т.ч. ссылки на dom элементы
 * @type {DataStorage}
 */
let dataStorage = {
    _storage: new WeakMap(),
    put: function (element, key, obj) {
        if (!this._storage.has(element)) {
            this._storage.set(element, new Map());
        }
        this._storage.get(element).set(key, obj);
    },
    get: function (element, key) {
        return this._storage.get(element)?.get(key);
    },
    has: function (element, key) {
        return this._storage.has(element) && this._storage.get(element).has(key);
    },
    remove: function (element, key) {
        var ret = this._storage.get(element).delete(key);
        if (this._storage.get(element).size !== 0) {
            this._storage.delete(element);
        }
        return ret;
    }
}

/**
 * 
 * @param {HTMLElement | string | number} target 
 */
function smoothScrollTo(target) {
    if (target instanceof HTMLElement) {
        window.scrollTo({ top: target.getBoundingClientRect().top - 50, left: 0, behavior: "smooth" });
    } else if (typeof target === 'string') {
        window.scrollTo({ top: document.querySelector(target).getBoundingClientRect().top - 50, left: 0, behavior: "smooth" });
    } else {
        window.scrollTo({ top: target, left: 0, behavior: "smooth" });
    }
}

/**
 * 
 * @param {ElementsOrSelector} item
 * @param {number} time 
 * @returns 
 */
function highlight(item, time) {
    toggleClass(item, 'tum-box-attention', true);

    return new Promise(resolve => {
        setTimeout(() => {
            toggleClass(item, 'tum-box-attention', false);
            resolve();
        }, time || 2000);
    });
}


/**
 * 
 * @param {ElementsOrSelector} element 
 * @param {string} selector 
 */
function find(element, selector) {
    let elements = getElements(element);

    return elements.flatMap(e => [...e.querySelectorAll(selector)]);
}

function focusInput(input){
    let fieldLength = input.value.length;
    let type = input.type;
    input.type = 'text';
    input.setSelectionRange(fieldLength, fieldLength);
    input.type = type;
    input.focus({
        preventScroll: true
    });
}

function remove(element) {
    let elements = getElements(element);
    for (let element of elements) {
        element.remove();
    }
}

window.dataStorage = dataStorage;
window.smoothScrollTo = smoothScrollTo;
window.setHtml = setHtml;

export {
    addEvent, addEventDelegate, toggleClass, getElements, appendHtml, prependHtml, setHtml, replaceWithHtml,
    insertHtmlAfter, insertHtmlBefore, htmlToDocumentFragment, htmlToDocumentFragmentCompat,
    documentHeight, dataStorage, onClickOutside, show, hide, toggle, empty, setProperty, setValue, hasClass, smoothScrollTo,
    highlight, find, focusInput, remove
};