import * as $ from "jquery";
import "bootstrap-growl-ifightcrime";

/**
 * 
 * @param {string} str 
 * @param {BootstrapGrowlIfightcrime.Options} [options] 
 * @returns 
 */
function bootstrapAlert(str, options = null) {
    if (!options) options = { type: 'info' };
    return $.bootstrapGrowl(str, options);
}

export { bootstrapAlert };
