import { ajaxSend } from "./fetch";
import { bootstrapAlert } from "./alert";

let isUnloading = false;

/**
 * 
 * @param {string} text 
 */
function logToConsoleDump(text){
    let element = document.createElement('p');
    element.innerHTML = text;
    let dump = document.getElementById('console-dump');
    dump?.appendChild(element);

    let logElement = document.getElementById('console-log');
    if (logElement) {
        logElement.innerHTML = dump.innerHTML;
        logElement.scrollTop = logElement.scrollHeight;
    } 
}

let disableLogError = false;

/**
 * 
 * @param {string} error 
 */
function logError(error) {
    if (disableLogError) return;

    ajaxSend({
        url: '/ArkStuff/jsError',
        data: { s: error }
    }).catch(() => {
        disableLogError = true;
        setTimeout(() => disableLogError = false, 5000);
    });
}

/**
 * 
 * @param {string} browser 
 * @param {number} ver 
 * @returns {boolean}
 */
function checkBrowser(browser, ver) {
    var isOldVersion = false;
    var item = browser.split(' ');
    if (item.length > 1 && Number(item[1]) < ver) {
        isOldVersion = true;
    }
    return isOldVersion;
}

let exceptions = [
    //signalr
    "Cannot send data if the connection is not in the 'Connected' State",
    "Failed to complete negotiation with the server: TypeError: NetworkError when attempting to fetch resource",
    "Server timeout elapsed without receiving a message from the server",

    'yandex.sovetnik'
];

let dev_exceptions = [
    'ym is not defined',
    'Failed to register a ServiceWorker'
]

/**
 * 
 * @param {Error} error 
 * @param {boolean} [quiet=false] 
 * @param {boolean} [sendError=true] 
 * @param {string} [additional] 
 * @returns 
 */
function handleError(error, quiet = false, sendError = true, additional = null) {
    if (isUnloading) return;
    if (exceptions.some(e => error.message?.includes(e) || false)) return;

    if (["dev.tu.market", "localhost"].includes(location.hostname) && dev_exceptions.some(e => error.message?.includes(e))) return;

    var logData = {
        Error: error.message,
        Url: location.href,
        Name: error.name,
        Stack: error.stack
    }

    let errorString = Object.keys(logData).map(function(key){ return key + ': ' + logData[key] }).join('\n') + ';\n' + (additional || '');
    if (sendError) {
        logError(errorString);
    }

    logToConsoleDump(`${logData.Name}:\n${logData.Error}\n${logData.Stack}`);

    if (quiet) return false;

    let browser = (function sayswho() {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();

    var isOldVersion = false;
    var oldBrowserMsg = ' :( кажется, у Вас очень старая версия браузера. Мы не можем показать Вам все функции нашего Маркета ...Вам стоит обновить браузер.';
    if (browser.indexOf("Firefox") > -1) {
        isOldVersion = checkBrowser(browser, 70);
    } else if (browser.indexOf("SamsungBrowser") > -1) {
        isOldVersion = checkBrowser(browser, 70);
    } else if (browser.indexOf("Opera") > -1 || browser.indexOf("OPR") > -1) {
        isOldVersion = checkBrowser(browser, 70);
    } else if (browser.indexOf("Trident") > -1) {
        isOldVersion = checkBrowser(browser, 11);
    } else if (browser.indexOf("Edge") > -1) {
        isOldVersion = checkBrowser(browser, 18);
    } else if (browser.indexOf("Chrome") > -1) {
        isOldVersion = checkBrowser(browser, 70);
    } else if (browser.indexOf("Safari") > -1) {
        isOldVersion = checkBrowser(browser, 5);
    } 
    if (!isOldVersion) {
        oldBrowserMsg = '';
    }

    if (error.message && error.message.indexOf("yaCounter51188528") == -1 && error.message.indexOf("ResizeObserver") == -1 && error.message.indexOf("chrome-extension://") == -1) {
        bootstrapAlert("Упс.. При загрузке возникла ошибка. Мы уже знаем о ней, и в ближайшее время исправим.  Попробуйте обновить страницу (F5), или вернуться Назад." + oldBrowserMsg);
    }

    return false;
}

function initErrorHandling() {
    window.addEventListener('error', error => handleError(error.error));
    window.addEventListener('unhandledrejection', e => { 
        handleError(e.reason);
    });

    window.addEventListener('beforeunload', () => isUnloading = true);
}

export { handleError, initErrorHandling };